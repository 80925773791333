<template>
	<div>
		<!-- 顶部轮播图 -->
		<div style="width: 100%;background-color: #FFFFFF;height: 200px;">
			<div style="margin: 0 auto;width: 1200px;">
				<div style="width: 100%;text-align: left;">
					<el-row :gutter="20">
						<el-col :span="24" style="margin-top: 20px;">
							<span class="typetitle">授课方式：</span>
							<span v-for="item in dataTeaching">
								<span v-if="item==checkTeaching" class="checktitle">{{item}}</span>
								<span v-else class="untitle" @click="clickTeaching(item)">{{item}}</span>
							</span>
						</el-col>
						<el-col :span="24" style="margin-top: 40px;">
							<span class="typetitle">课程分类：</span>
							<span v-for="item in dataCurriculum">
								<span v-if="item==checkCurriculum" class="checktitle">{{item}}</span>
								<span v-else class="untitle" @click="clickCurriculum(item)">{{item}}</span>
							</span>
						</el-col>
						<el-col :span="24" style="margin-top: 40px;">
							<span class="typetitle">出品单位：</span>
							<span v-for="item in dataProduct">
								<span v-if="item==checkProduct" class="checktitle">{{item}}</span>
								<span v-else class="untitle" @click="clickProduct(item)">{{item}}</span>
							</span>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
		<div class="talent">
			<div style="width: 100%;">
				<el-row :gutter="25">
					<el-col :span="6" v-for="(item,index) in talentData" :key="index" style="margin-top: 32px;">
						<el-card :body-style="{padding: '0px'}">
							<el-image class="talentimg" :src="item.image" @click="toInfo(item.id)" @error="itemError(item)"></el-image>
              <div style="margin-left: 16px;">
                <p class="talentp1">{{ item.name }}</p>
                <p v-if="item.techingMethod == '1'" class="talentp2"><span class="talentspan5">在线观看</span></p>
                <p v-if="item.techingMethod == '0'" class="talentp2">{{ item.num }}门课程<span v-if="item.isStart == '1'" class="talentspan4">可报名</span><span v-if="item.isStart == '0'" class="talentspan4">可预约</span></p>
                <p class="talentp3"><span class="talentspan1">¥</span><span class="talentspan2">{{
                    item.fee
                  }}</span><span class="talentspan3">参考费用</span></p>
              </div>
						</el-card>
					</el-col>
				</el-row>
			</div>
			<el-pagination style="margin-top: 20px;" class="pagination" @current-change="getTalent" :current-page.sync="queryData.pageNo"
			  :page-size="queryData.pageSize" layout="total, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
import { baseURL } from "@/utils/request/apis";
import defaultImage from "@/assets/images/home/default_companyLogo.png";
export default {
	name: "TalentMore",
	data() {
		return {
			url:{
				getLzCourseUrl:"/lz_course/lzCourse/firstlist",
			},
			checkTeaching:'',
			checkCurriculum:'',
			checkProduct:'',
			dataTeaching:[],
			dataCurriculum:[],
			dataProduct:[],
			talentData:[],
			total:0,
			queryData:{
				teching_method:'',
				classification:'',
				production:'',
				pageNo:1,
				pageSize:20
			},
      defaultImage
		}
	},
	methods:{
		itemError(item){
			item.image = this.defaultImage;
		},
		clickTeaching(name){
			this.queryData.pageNo = 1;
			this.checkTeaching = name;
			if(name == "在线公开课"){
				this.dataCurriculum = ['全部'];
				this.checkCurriculum = '全部';
			}
			if(name == "线下培训"){
				this.dataCurriculum = ['全部','学历提升','技能证书培训','职业素养提升'];
				this.checkCurriculum = '全部';
			}
			this.getTalent();
		},
		clickCurriculum(name){
			this.queryData.pageNo = 1;
			this.checkCurriculum = name;
			this.getTalent();
		},
		clickProduct(name){
			this.queryData.pageNo = 1;
			this.checkProduct = name;
			this.getTalent();
		},
		getTalent(){
			this.getQueryData();
			let query = {pageNo:this.queryData.pageNo,pageSize:this.queryData.pageSize,techingMethod:this.queryData.teching_method};
			if(this.queryData.classification != -1){
				query.classification = this.queryData.classification;
			}
			if(this.queryData.production != -1){
				query.production = this.queryData.production;
			}
			this.$http.get(baseURL+this.url.getLzCourseUrl, query).then((res) => {
				this.talentData = res.result.records;
				for(let i = 0; i < this.talentData.length; i++){
					this.talentData[i].image = baseURL + '/' + this.talentData[i].image;
				}
				this.total = res.result.total;
			});
		},
		toInfo(id){
			if(this.checkTeaching == "在线公开课"){
				const url = this.$router.push({ name: 'TPExpertInfo', query: { id: id }});
				// window.open(url.href);
			}else{
				const url = this.$router.push({ name: 'TPTalentInfo', query: { id: id }});
				// window.open(url.href);
			}
			
		},
		getQueryData(){
			if(this.checkTeaching == "在线公开课"){
				this.queryData.teching_method = 1;
			}else{
				this.queryData.teching_method = 0;
			}
			if(this.checkCurriculum == "全部"){
				this.queryData.classification = -1;
			}else if(this.checkCurriculum == "学历提升"){
				this.queryData.classification = 0;
			}else if(this.checkCurriculum == "技能证书培训"){
				this.queryData.classification = 1;
			}else{
				this.queryData.classification = 2;
			}
			if(this.checkProduct == "全部"){
				this.queryData.production = -1;
			}else if(this.checkProduct == "经纬人才公司"){
				this.queryData.production = 0;
			}else{
				this.queryData.production = 1;
			}
		}
	},
	created() {
		if(this.$route.query.name == "学历提升"){
			this.dataTeaching = ['线下培训','在线公开课'];
			this.dataCurriculum = ['全部','学历提升','技能证书培训','职业素养提升'];
			this.dataProduct = ['全部','经纬人才公司','合作机构'];
			this.checkTeaching = '线下培训';
			this.checkCurriculum = '学历提升';
			this.checkProduct = '全部';			
			this.queryData.classification = 0;			
		}
		if(this.$route.query.name == "技能证书培训"){
			this.dataTeaching = ['线下培训','在线公开课'];
			this.dataCurriculum = ['全部','学历提升','技能证书培训','职业素养提升'];
			this.dataProduct = ['全部','经纬人才公司','合作机构'];
			this.checkTeaching = '线下培训';
			this.checkCurriculum = '技能证书培训';
			this.checkProduct = '全部';
			this.queryData.classification = 1;
		}
		if(this.$route.query.name == "职业素养提升"){
			this.dataTeaching = ['线下培训','在线公开课'];
			this.dataCurriculum = ['全部','学历提升','技能证书培训','职业素养提升'];
			this.dataProduct = ['全部','经纬人才公司','合作机构'];
			this.checkTeaching = '线下培训';
			this.checkCurriculum = '职业素养提升';
			this.checkProduct = '全部';
			this.queryData.classification = 2;
		}
		if(this.$route.query.name == "在线公开课"){
			this.dataTeaching = ['线下培训','在线公开课'];
			this.dataCurriculum = ['全部'];
			this.dataProduct = ['全部','经纬人才公司','合作机构'];
			this.checkTeaching = '在线公开课';
			this.checkCurriculum = '全部';
			this.checkProduct = '全部';
		}
		this.getTalent();
	}
}
</script>

<style scoped>
.typetitle {
	padding-top: 12px;
	padding-bottom: 12px;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;
	font-family: 'PingFang SC';
	font-style: normal;
	font-size: 14px;
	font-weight: 400;
	color: #909399;
}
.checktitle {
	padding-top: 12px;
	padding-bottom: 12px;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;
	font-family: 'PingFang SC';
	font-style: normal;
	font-size: 14px;
	font-weight: 400;
	background-color: #00CF97;
	border-radius: 5px;
	color: #FFFFFF;
}
.untitle {
	padding-top: 12px;
	padding-bottom: 12px;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;
	font-family: 'PingFang SC';
	font-style: normal;
	font-size: 14px;
	font-weight: 400;
	color: #303133;
}

.talent {
	margin: 20px auto;
  width: 1200px;
}

.talentimg {
	width: 100%;
	height: 170px;
}

.talentp1 {
	font-family: 'PingFang SC'; 
	font-style: normal;
	font-weight: 800;
	font-size: 18px;
	text-align: left;
	margin-left: 5px;
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.talentp2 {
	font-family: 'PingFang SC'; 
	font-style: normal;
	font-weight: 200;
	font-size: 13px;
	text-align: left;
	margin-left: 5px;
}

.talentp3 {
	font-family: 'PingFang SC'; 
	font-style: normal;
	text-align: left;
	margin-left: 5px;
}

.talentspan1 {
	color: #FF434E;
	font-size: 14px;
	font-weight: 500;
}

.talentspan2 {
	color: #FF434E;
	font-size: 20px;
	font-weight: 500;
}

.talentspan3 {
	margin-left: 10px; 
	background-color: #FFF2E6;
	color: #C35424;
	font-size: 18px;
	font-weight: 500;
}

.talentspan4 {
  margin-left: 10px;
  color: #C35424;
  font-size: 18px;
  font-weight: 500;
}
.talentspan5 {
  color: #C35424;
  font-size: 18px;
  font-weight: 500;
}
</style>